import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import LinkButton from '../LinkButton/LinkButton';
import Notification from '../Notification';
import LoginButton from './NavbarStyle';
import NewSliderNav from '../SliderNav/NewSliderNav';

const TOPIC_URL = `/topic`

const HamburgerMenu = dynamic(() => import('../HamburgerMenu/HamburgerMenu'), {
  ssr: false,
})

const Sidedrawer = dynamic(() => import('../SideDrawer/SideDrawer'))


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0, 1),
  },
  main: {
    marginTop: theme.spacing(16),
  },
  mainMob: {
    marginTop: '7rem',
  },
  toolbar: {
    minHeight: '40px',
  },
  wordCloud: {
    height: 300,
    width: '96%',
    backgroundColor: '#000',
    margin: '20px auto',
  },

  textDecorationNone: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 'bold',
    margin: theme.spacing(0, 1)
  },

  active: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 'bold',
    margin: theme.spacing(0, 1)
  },

  searchMargin: {
    margin: theme.spacing(0, 2),
    cursor: 'pointer',

    ['@media (max-width:425px)']: {
      margin: theme.spacing(0, 1),
    },
  },
  navBG: {
    background:"#d4d4d4fa",
    borderBottom: "2px solid rgb(15, 174, 79)"
  }
}));

function Navbar(props) {
  const classes = useStyles();
  const router = useRouter();
  const matches = useMediaQuery('(max-width:768px)');
  const NotMatches = useMediaQuery('(min-width:768px)');
  const mobile = useMediaQuery('(max-width: 426px)');
  const xtraMob = useMediaQuery('(max-width: 364px)');
  
  const [OpenSearchBar, setOpenSearchBar] = useState(false);
  const [open, setOpen] = React.useState(false);

  const prevOpenRef = React.useRef(open);
  const prevOpen = prevOpenRef.current;
  const anchorRef = React.useRef(null);

  const handleToggle = (event) => {
    event.preventDefault();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setTimeout(function () { setOpen(false); }, 200);
    // setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpenRef.current = open;
  }, [open]);

  useEffect(() => {
  }, [OpenSearchBar]);

  function handleOpenSearchPage() {
    router.push('/explore');
  }

  return (
    <>
      {open && <Sidedrawer open={open} handleClose={handleClose} handleListKeyDown={handleListKeyDown}
        drawerClosedHandler={handleClose} show={open} clicked={handleToggle} />
      }
      <AppBar position="fixed" className={classes.navBG}>
        <div>
          <Toolbar className={classes.toolbar}>
            <HamburgerMenu handleToggle={handleToggle}
              anchorRef={anchorRef}
              open={open}
            />
            {!OpenSearchBar && (
              <Box component="div" className={classes.title}>
                <Link href={"/"} prefetch={false}>
                <a>                  
                <svg width="105" height="26" viewBox="0 0 105 26" fill="none" id="back-to-top-anchor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.34904 5.94065C6.51957 5.94065 7.4451 6.36927 8.12564 7.22651C8.80618 8.0666 9.14645 9.2153 9.14645 10.6726V20.3422H7.75815V10.8526C7.75815 9.73821 7.54038 8.88098 7.10483 8.28091C6.66929 7.68084 6.04319 7.38081 5.22654 7.38081C4.60045 7.38081 3.94033 7.56083 3.24617 7.92087C2.55202 8.26377 1.93273 8.72667 1.3883 9.30959V20.3422H0V0H1.3883V7.63798C1.98718 7.08935 2.62688 6.6693 3.30742 6.37784C3.98796 6.08638 4.6685 5.94065 5.34904 5.94065Z" fill="white"/>
                <path d="M20.72 16.819C20.72 17.6076 20.7677 18.2334 20.863 18.6963C20.9718 19.1421 21.1556 19.545 21.4142 19.905C21.2781 20.0765 21.1148 20.2136 20.9242 20.3165C20.7473 20.4194 20.5771 20.4708 20.4138 20.4708C20.1416 20.4708 19.9102 20.2737 19.7196 19.8793C19.5427 19.485 19.427 18.9449 19.3726 18.2592C18.9506 18.9792 18.3858 19.5536 17.678 19.9822C16.9703 20.3937 16.2285 20.5994 15.4527 20.5994C14.4182 20.5994 13.588 20.2651 12.9619 19.5964C12.3358 18.9107 12.0227 18.0191 12.0227 16.9219C12.0227 15.3103 12.642 14.0501 13.8806 13.1414C15.1328 12.2328 16.9499 11.7099 19.3317 11.5727V10.7498C19.3317 9.6182 19.1004 8.77811 18.6376 8.22948C18.1884 7.6637 17.5011 7.38081 16.5756 7.38081C15.9767 7.38081 15.4186 7.54369 14.9014 7.86944C14.3978 8.17804 13.8534 8.70096 13.2681 9.43818C13.1865 9.54105 13.0844 9.59248 12.9619 9.59248C12.8258 9.59248 12.6761 9.5239 12.5127 9.38674C12.3494 9.23244 12.1929 9.01813 12.0432 8.74382C12.6556 7.83515 13.3498 7.14079 14.1256 6.66073C14.915 6.18068 15.7521 5.94065 16.6368 5.94065C17.9026 5.94065 18.8962 6.36927 19.6176 7.22651C20.3526 8.08374 20.72 9.25816 20.72 10.7498V16.819ZM15.5547 19.1592C16.2897 19.1592 16.9839 18.9021 17.6372 18.3877C18.2905 17.8734 18.8554 17.1447 19.3317 16.2018V13.0129C15.4118 13.2186 13.4519 14.513 13.4519 16.8961C13.4519 18.4049 14.1528 19.1592 15.5547 19.1592Z" fill="white"/>
                <path d="M28.4295 20.5994C26.7826 20.5994 25.4964 19.9479 24.5709 18.6449C23.659 17.3419 23.203 15.5246 23.203 13.1929C23.203 11.7184 23.4072 10.4411 23.8155 9.36103C24.2374 8.26377 24.8363 7.42367 25.6121 6.84075C26.3879 6.24069 27.2998 5.94065 28.3479 5.94065C29.6001 5.94065 30.6277 6.34355 31.4307 7.14936C32.2474 7.93802 32.7238 9.01813 32.8599 10.3897C32.7374 10.4583 32.5672 10.5269 32.3495 10.5954C32.1453 10.664 31.9752 10.6983 31.8391 10.6983C31.7029 10.6983 31.6009 10.6555 31.5328 10.5697C31.4784 10.4669 31.4171 10.2611 31.3491 9.95252C31.1993 9.14672 30.8591 8.52094 30.3283 8.07517C29.7974 7.61226 29.1373 7.38081 28.3479 7.38081C27.6129 7.38081 26.9596 7.62084 26.3879 8.10089C25.8299 8.58094 25.3943 9.26673 25.0813 10.1583C24.7819 11.0326 24.6321 12.0442 24.6321 13.1929C24.6321 15.0102 24.9724 16.459 25.6529 17.5391C26.3471 18.6192 27.2726 19.1592 28.4295 19.1592C29.3142 19.1592 30.0696 18.9021 30.6957 18.3877C31.3218 17.8562 31.7914 17.0847 32.1045 16.0732C32.295 16.0732 32.4515 16.1761 32.574 16.3818C32.7101 16.5875 32.7782 16.8276 32.7782 17.1019C32.7782 17.7019 32.574 18.2763 32.1657 18.8249C31.771 19.3564 31.2334 19.785 30.5528 20.1108C29.8859 20.4365 29.1781 20.5994 28.4295 20.5994Z" fill="white"/>
                <path d="M44.4314 20.3422C44.2 20.3422 43.9346 20.3594 43.6351 20.3937C43.3902 20.428 43.2404 20.4451 43.186 20.4451C42.9274 20.4451 42.73 20.3079 42.5939 20.0336L39.9602 14.6845C39.5655 13.8787 38.8509 13.5015 37.8165 13.5529L36.8774 13.6044V20.3422H35.4891V0H36.8774V12.0099L37.6736 11.9842C37.9594 11.967 38.2044 11.8984 38.4086 11.7784C38.6128 11.6413 38.7965 11.4355 38.9598 11.1612L42.0223 6.19782H43.7372L40.1031 11.8042C40.0759 11.8384 40.0147 11.9327 39.9194 12.087C39.8241 12.2242 39.7016 12.3442 39.5519 12.4471C40.2188 12.67 40.702 13.0814 41.0015 13.6815L44.4314 20.3422Z" fill="white"/>
                <path d="M55.4127 12.2671C55.4127 12.6614 55.3991 13.0557 55.3719 13.4501H47.2463C47.2735 15.2331 47.5865 16.6218 48.1854 17.6162C48.7979 18.6106 49.6418 19.1078 50.717 19.1078C51.452 19.1078 52.0713 18.9278 52.5749 18.5678C53.0921 18.2077 53.5208 17.6334 53.8611 16.8447C53.9564 16.6218 54.0993 16.5104 54.2898 16.5104C54.5076 16.5104 54.8071 16.6733 55.1882 16.999C54.8887 18.1134 54.3375 18.9964 53.5344 19.6479C52.7314 20.2822 51.7923 20.5994 50.717 20.5994C49.7234 20.5994 48.8523 20.3079 48.1037 19.725C47.3688 19.125 46.8039 18.2763 46.4092 17.179C46.0145 16.0646 45.8171 14.753 45.8171 13.2443C45.8171 10.9641 46.2663 9.18101 47.1646 7.89515C48.0629 6.59215 49.2947 5.94065 50.8599 5.94065C52.2891 5.94065 53.4051 6.49786 54.2082 7.61226C55.0112 8.72667 55.4127 10.2783 55.4127 12.2671ZM53.9836 12.0613C53.9836 8.97527 52.922 7.43225 50.7987 7.43225C49.7643 7.43225 48.9476 7.82657 48.3487 8.61523C47.7499 9.40389 47.396 10.5526 47.2871 12.0613H53.9836Z" fill="white"/>
                <path d="M62.468 6.0178C62.9443 6.0178 63.305 6.12067 63.55 6.32641C63.8086 6.515 63.9379 6.80646 63.9379 7.20079C63.9379 7.47511 63.8631 7.72371 63.7134 7.94659C63.3867 7.72371 62.9375 7.61226 62.3659 7.61226C61.454 7.61226 60.719 8.0666 60.1609 8.97527C59.6165 9.88394 59.3443 11.0755 59.3443 12.55V20.3422H57.956V6.19782H59.3443V8.74382C59.6573 7.95516 60.0997 7.30366 60.6713 6.78932C61.243 6.27497 61.8419 6.0178 62.468 6.0178Z" fill="white"/>
                <path d="M71.0916 5.94065C72.616 5.94065 73.7797 6.57501 74.5827 7.84372C75.3858 9.09529 75.7873 10.8955 75.7873 13.2443C75.7873 14.7359 75.5763 16.0389 75.1544 17.1533C74.7461 18.2506 74.1472 19.0992 73.3578 19.6993C72.582 20.2994 71.67 20.5994 70.622 20.5994C70.1048 20.5994 69.5808 20.5308 69.05 20.3937C68.5327 20.2737 68.07 20.1108 67.6617 19.905V26H66.2734V11.0069C66.2734 8.4695 66.1917 6.96076 66.0284 6.48071C66.4639 6.22354 66.811 6.09496 67.0696 6.09496C67.4643 6.09496 67.6617 6.59215 67.6617 7.58655V7.68942C68.1108 7.12364 68.628 6.69502 69.2133 6.40356C69.8122 6.09496 70.4383 5.94065 71.0916 5.94065ZM70.622 19.1592C71.7925 19.1592 72.6976 18.6449 73.3374 17.6162C73.9907 16.5875 74.3173 15.1302 74.3173 13.2443C74.3173 9.33531 73.2421 7.38081 71.0916 7.38081C70.4655 7.38081 69.853 7.56083 69.2541 7.92087C68.6688 8.28091 68.138 8.79525 67.6617 9.4639V18.3877C68.5055 18.9021 69.4923 19.1592 70.622 19.1592Z" fill="white"/>
                <path d="M92.7839 20.5994C92.1714 20.5994 91.5385 20.4708 90.8852 20.2136C90.2319 19.9565 89.6875 19.6136 89.2519 19.185C88.8164 18.7563 88.5986 18.302 88.5986 17.822C88.5986 17.5991 88.6531 17.4019 88.7619 17.2305C88.8844 17.0419 89.0342 16.9133 89.2111 16.8447C89.7011 17.6505 90.2319 18.242 90.8036 18.6192C91.3752 18.9792 92.0626 19.1592 92.8656 19.1592C93.7231 19.1592 94.3696 18.9107 94.8051 18.4135C95.2543 17.9163 95.4789 17.2562 95.4789 16.4332C95.4789 15.8846 95.3428 15.4388 95.0705 15.0959C94.8119 14.7531 94.4853 14.4959 94.0906 14.3244C93.7095 14.153 93.1786 13.9558 92.4981 13.7329C91.7087 13.4758 91.069 13.2272 90.579 12.9871C90.1026 12.7471 89.6943 12.3871 89.354 11.907C89.0274 11.4098 88.864 10.7498 88.864 9.9268C88.864 9.05242 89.0478 8.3152 89.4153 7.71513C89.7828 7.11507 90.2523 6.6693 90.824 6.37784C91.3956 6.08638 91.9809 5.94065 92.5798 5.94065C93.4373 5.94065 94.2131 6.15496 94.9072 6.58358C95.615 7.0122 96.1662 7.63798 96.5609 8.46093C96.2343 8.88955 95.9552 9.10386 95.7239 9.10386C95.5469 9.10386 95.3768 8.98384 95.2135 8.74382C94.8868 8.26376 94.5057 7.9123 94.0702 7.68942C93.6482 7.44939 93.131 7.32938 92.5185 7.32938C91.8244 7.32938 91.2731 7.53511 90.8648 7.94659C90.4565 8.35806 90.2523 8.99242 90.2523 9.84965C90.2523 10.3469 90.3748 10.7498 90.6198 11.0584C90.8648 11.367 91.1711 11.607 91.5385 11.7784C91.906 11.9327 92.4096 12.1128 93.0493 12.3185C93.866 12.5585 94.5261 12.8071 95.0297 13.0643C95.5469 13.3215 95.9825 13.7244 96.3364 14.273C96.6902 14.8045 96.8672 15.5246 96.8672 16.4332C96.8672 17.7019 96.4929 18.7135 95.7443 19.4679C95.0093 20.2222 94.0225 20.5994 92.7839 20.5994Z" fill="white"/>
                <path d="M104.673 18.6192C104.769 18.7049 104.843 18.8335 104.898 19.0049C104.966 19.1592 105 19.3221 105 19.4936C105 19.8193 104.891 20.0508 104.673 20.1879C104.238 20.4622 103.673 20.5994 102.979 20.5994C102.094 20.5994 101.386 20.2994 100.856 19.6993C100.325 19.0821 100.059 18.2592 100.059 17.2305V7.58655H97.936V6.19782H100.059V2.34026H101.448V6.19782H104.388V7.58655H101.448V16.8961C101.448 18.3363 101.938 19.0564 102.918 19.0564C103.544 19.0564 104.129 18.9106 104.673 18.6192Z" fill="white"/>
                <path d="M76.8295 12.5735H78.4245V13.913H76.8295V12.5735ZM78.903 7.55065L78.1587 8.48825L79.2752 9.89466L80.0196 8.95705L78.903 7.55065ZM83.2096 5.87636H82.1462V7.88551H83.2096V5.87636ZM86.9313 12.5735V13.913H88.5263V12.5735H86.9313ZM81.6146 19.9404C81.6146 20.3422 81.8272 20.6101 82.1462 20.6101H83.2096C83.5286 20.6101 83.7413 20.3422 83.7413 19.9404V19.2707H81.6146V19.9404ZM86.4528 7.55065L85.3363 8.95705L86.0806 9.89466L87.1971 8.48825L86.4528 7.55065ZM85.8679 13.2432C85.8679 14.7166 85.2299 16.056 84.2729 16.7258V17.9313C84.2729 18.3331 84.0603 18.601 83.7413 18.601H81.6146C81.2956 18.601 81.0829 18.3331 81.0829 17.9313V16.7258C80.1259 16.056 79.4879 14.7166 79.4879 13.2432C79.4879 11.0332 80.9234 9.22494 82.6779 9.22494C84.4324 9.22494 85.8679 11.0332 85.8679 13.2432ZM84.8046 13.2432C84.8046 11.7632 83.8529 10.5644 82.6779 10.5644C81.5029 10.5644 80.5512 11.7632 80.5512 13.2432C80.5512 14.7233 81.5029 15.9221 82.6779 15.9221C83.8529 15.9221 84.8046 14.7233 84.8046 13.2432Z" fill="#0FAE4F"/>
                </svg>
                  <span className="logo_span">Post Your hacks</span>
                </a>
                </Link>
              </Box>
            )}

            <SearchIcon
              className={classes.searchMargin}
              onClick={handleOpenSearchPage}
            />

            {NotMatches &&
              <>
                <Link href={`/following`} prefetch={false}>
                  <a className={classes.textDecorationNone}>
                    <Typography variant="body1" className={
                      router.pathname === TOPIC_URL ? `${classes.active}` : `${classes.textDecorationNone}`
                    }>
                      Following
                  </Typography>
                  </a>
                </Link>
              {!props.writting && <LoginButton
                variant="contained"
                component={LinkButton}
                color="primary"
                className={classes.margin}
                href="/StartWriting"
              >
                {xtraMob ?  "Write" : "Start Writing"}
              </LoginButton>
              }
              </>
            }
            <Notification />
            
            {/* {desktopMenu &&
              <Sidebar />} */}
          </Toolbar>
          {!OpenSearchBar && <NewSliderNav />}
        </div>
      </AppBar>
      <main className={!matches ? classes.main : classes.mainMob}>
        {props.children}
      </main>
    </>
  );
}

export default Navbar;