import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { color } from '@material-ui/system';

const LoginButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '6px 8px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#fff',
        borderColor: '#fff',
        color: "#000",
        outline: "none",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#10ae4f',
            borderColor: '#10ae4f',
            boxShadow: 'none',
            color:"#fff"

        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#10ae4f',
            borderColor: '#10ae4f',
            color:"#fff"

        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);


export default LoginButton