import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../Hooks/http-hook'
import Badge from '@material-ui/core/Badge';
import CancelIcon from '@material-ui/icons/Cancel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function RenderNotification(props) {

    const { isLoading, error, sendRequest } = useHttpClient();
    const [renderNotification, setRenderNotification] = useState(null);
    const [followedArticleList, setFollowedArticleList] = useState(null)

    const routeHandler = (followArticle, current_article) => {
        let data = JSON.parse(localStorage.getItem('articles'))
        let updatedArticle = data.map((d, i) => {
            return {
                ...d,
                curr_reply: d.id === followArticle[0].id ? followArticle[0].reply_count : d.curr_reply
            }
        })
        localStorage.setItem('articles', JSON.stringify(updatedArticle))
        window.location = current_article.get_share_url
        // router.push(current_article.get_share_url)
    }

    const fetchFollowedArticleNotification = async (article) => {
        const followedArticleData = await sendRequest(`/api/api/v1/reply-count/${article.id}/`);
        if (!followedArticleData.message) {
            let badgeInvisible = followedArticleData[0].id === article.id && followedArticleData[0].reply_count > article.curr_reply

            if (badgeInvisible) {
                followedArticleData[0].reply_count > article.curr_reply ? props.setInvisibleBadge(false) : props.setInvisibleBadge(true)
            }

            let articleList;
            articleList = (
                <>
                    <ListItemText primary={
                        <>
                            <Badge
                                color="error"
                                variant="dot"
                                invisible={!badgeInvisible}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}>
                                <span
                                    onClick={() => routeHandler(followedArticleData, article)}
                                    className={[props.classes.notification, 'not-link'].join(' ')}>
                                    {article.title}

                                    {
                                        badgeInvisible && <p className="delete">{followedArticleData[0].reply_count - article.curr_reply} new activity</p>
                                    }
                                </span>
                            </Badge>
                        </>
                    }
                    />
                    <ListItemIcon onClick={() => props.handleDelete(article)}>
                        <CancelIcon fontSize="small" className={props.classes.icon} />
                    </ListItemIcon>
                </>
            )
            setRenderNotification(articleList)
        }
    }

    const memoizedCallback = useCallback(
        (article) => {
            fetchFollowedArticleNotification(article)
        },
        [],
    );

    useEffect(() => {
        if (localStorage.getItem('articles')) {
            setFollowedArticleList(JSON.parse(localStorage.getItem('articles')))
        }
        memoizedCallback(props.article)
    }, [])

    return (
        <>
            {renderNotification ?? renderNotification}
        </>
    );
}

export default RenderNotification;