import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import Divider from '@material-ui/core/Divider';
// import BookmarksIcon from '@material-ui/icons/Bookmarks';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RenderNotification from './renderNotification';


const useStyles = makeStyles({
  menu: {
    "white-space": 'pre-wrap'
  },
  icon: {
    padding: '8px',
    color: "#000",

    ['@media (max-width:360px)']: {
      padding: '2px'
    },
  },
  notification: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  notificationTitle: {
    fontSize: '1rem',
    textAlign: "center",
    color: '#06c',
    fontWeight: 800
  }
});

export default function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ followedArticleList, setFollowedArticleList ] = useState(null)
  const [invisible, setInvisible] = React.useState(true);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (article) => {

    let followArticleFromLocalStorage = JSON.parse(localStorage.getItem('articles'));

    const updatedFollowArticles = followArticleFromLocalStorage.filter((ar) => {
        return ar.id !== article.id
    })
    setFollowedArticleList(updatedFollowArticles)

    localStorage.setItem('articles', JSON.stringify(updatedFollowArticles))
  }



  useEffect(() => {
    if(localStorage.getItem('articles') && JSON.parse(localStorage.getItem('articles')).length > 0){
        setFollowedArticleList(JSON.parse(localStorage.getItem('articles')))
    }
  }, []);


  const setInvisibleBadge = (value) => {
    setInvisible(value)
  }


  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.icon}
      >
        <Badge color="error" variant="dot" invisible={invisible}>
          {/* <BookmarksIcon color="secondary"/> */}
          <NotificationsIcon color="secondary"/>
        </Badge>
        
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem className={classes.menu}>
          <p className={classes.notificationTitle}>
            Your Recently Followed Articles
          </p>
            
        </MenuItem>
        <Divider variant="middle"/>

        {followedArticleList && followedArticleList.map((article) => (
          
          <MenuItem key={article.id} className={classes.menu}>
            <RenderNotification 
              article={article} 
              classes={classes} 
              handleDelete={handleDelete}
              setInvisibleBadge={setInvisibleBadge}
            />
          </MenuItem>
    
        ))}
        
      </Menu>
    </div>
  );
}